import { AuthState } from '@/auth/userStates';
import Routes from '@/router/routes';
import { PageRedirect, RedirectProps } from '../pageRedirect.types';

const authRedirect = (props: RedirectProps): PageRedirect | undefined => {
  const { authRequired, userAuthentication } = props;
  const isLoggedIn = userAuthentication.authState === AuthState.LOGGED_IN;
  if (authRequired && !isLoggedIn) {
    return {
      redirect: {
        destination: `${Routes.HOME}?auth=1`,
        permanent: false
      }
    };
  }
  return undefined;
};

export default authRedirect;
