import cookie from 'next-cookies';
import { LocalizeCustomerCartInput } from '@pizza-hut-us-development/client-core';

import { CC_LOCALIZATION, CART_CC_ID } from '@/configuration/constants';

export type LocalizationData = {
  cartId?: string | null;
  localizationDetails?: LocalizeCustomerCartInput | null;
};

export function getLocalizationData(ctx: any): LocalizationData {
  const cartId = cookie(ctx)[CART_CC_ID] ?? null;

  // JSON is parsed automatically but cookie(ctx) return type is always string
  // https://github.com/matthewmueller/next-cookies#readme
  const localizationDetails = cookie(ctx)[CC_LOCALIZATION] as unknown as LocalizeCustomerCartInput | null ?? null;

  return { cartId, localizationDetails };
}
